import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { MARKS, BLOCKS } from "@contentful/rich-text-types";

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="fw-bold">{text}</span>,
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => (
      <img
        className="w-50 m-auto"
        src={node.data?.target?.fields?.file?.url}
        alt={node.data?.target?.fields?.title}
      />
    ),
  },
};

const LiveVineyardStage = ({ info }) => {
  const upcomingShows = info.fields.upcomingShows;
  return (
    <div className="container p-t-100 m-b-50">
      <h1 className="huge-text-no-quote">LIVE!</h1>
      <h2 className="">from the vineyard stage</h2>
      {documentToReactComponents(upcomingShows, options)}
    </div>
  );
};

export default LiveVineyardStage;
