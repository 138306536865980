import React, { useState } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

const SECTION = {
  MAIN: "main",
  EXTRAS: "extras",
  PARTNERS: "partners",
};

export const paths = [
  //  { path: "/", text: "Home", section: SECTION.MAIN, external: false },
  {
    path: "/whats-happening",
    text: "What's Happening?",
    section: SECTION.MAIN,
    external: false,
  },
  {
    path: "/our-wines",
    text: "Our Wines",
    section: SECTION.EXTRAS,
    external: false,
  },
  {
    path: "/the-enoteca",
    text: "Enoteca Vineyard Wine Bar",
    section: SECTION.MAIN,
    external: false,
  },
  {
    path: "/menu",
    text: "Wine List and Enoteca Menu",
    section: SECTION.MAIN,
    external: false,
  },
  {
    path: "/auburn-road-email-club",
    text: "Email Club",
    section: SECTION.MAIN,
    external: false,
  },
  {
    path: "/auburn-road-csa",
    text: "Wine CSA",
    section: SECTION.MAIN,
    external: false,
  },
  { path: "/faq", text: "FAQ's", section: SECTION.EXTRAS, external: false },
  {
    path: "/wine-stories",
    text: "Wine Stories",
    section: SECTION.EXTRAS,
    external: false,
  },
  {
    path: "/goodkarmacafephilly",
    text: "Good Karma Cafe Philly",
    section: SECTION.MAIN,
    external: false,
  },
  {
    path: "/tours-and-tastings",
    text: "Tours & Guided Tastings",
    section: SECTION.EXTRAS,
    external: false,
  },
  {
    path: "/dinner-in-the-vineyards",
    text: "Dinners in the Vines",
    section: SECTION.EXTRAS,
    external: false,
  },
  // {
  //   path: "/live-at-the-vineyard-stage",
  //   text: "Live at the Vineyard Stage",
  //   section: SECTION.EXTRAS,
  //   external: false,
  // },
  {
    path: "/the-winemakers",
    text: "Meet The Winemakers",
    section: SECTION.EXTRAS,
    external: false,
  },
  {
    path: "/cowtown",
    text: "Cowtown Covered Wagon Tours",
    section: SECTION.EXTRAS,
    external: false,
  },
  {
    path: "/in-the-press",
    text: "In The Press",
    section: SECTION.EXTRAS,
    external: false,
  },
  {
    path: "/private-events",
    text: "Book Your Private Event",
    section: SECTION.EXTRAS,
    external: false,
  },
  {
    path: "/wine-playlists",
    text: "Spotify Playlists",
    section: SECTION.EXTRAS,
    external: false,
  },
  {
    path: "/sustainability",
    text: "Sustainability And Giving Back",
    section: SECTION.EXTRAS,
    external: false,
    subLinks: {
      Sustainability: "/sustainability",
      "Auburn Road Gives Back": "/giving-back",
    },
  },
  {
    path: "https://twobridgestrail.com/",
    text: "Two Bridges Wine Trail",
    section: SECTION.PARTNERS,
    external: true,
  },
  {
    path: "https://www.newjerseywines.com/",
    text: "Garden State Wine Growers Assn.",
    section: SECTION.PARTNERS,
    external: true,
  },
  {
    path: "https://visitsouthjersey.com/",
    text: "Visit South Jersey",
    section: SECTION.PARTNERS,
    external: true,
  },
  {
    path: "https://outercoastalplain.com/",
    text: "Outer Coastal Plain Vineyard Assn",
    section: SECTION.PARTNERS,
    external: true,
  },
  {
    path: "https://thewinemakersco-op.com/",
    text: "The Winemakers Co-Op",
    section: SECTION.PARTNERS,
    external: true,
  },
  {
    path: "https://drive.google.com/file/d/1oeuPzoC7URpRgp9PHdFe_at7E5T7lX9_/view",
    text: "I Want To Work Here",
    section: SECTION.EXTRAS,
    external: true,
  },
];

export const MenuLinks = ({
  currentPage,
  toggleContactOverlayFn,
  closeMenu,
}) => {
  const close = () => {
    closeMenu();
    togglePartners(false);
    toggleExtras(false);
    toggleSustainability(false);
  };
  const [extras, toggleExtras] = useState(false);
  const [partners, togglePartners] = useState(false);
  const [sustainability, toggleSustainability] = useState(false);
  const contactClick = () => {
    toggleContactOverlayFn();
    close();
  };

  const renderLinks = (section, customClass) => {
    return paths
      .filter((item) => item.section === section)
      .map((item, i) => {
        const { path, text, external, section } = item;
        const renderOnClick = () => {
          return item.subLinks
            ? () => toggleSustainability(!sustainability)
            : close;
        };
        const props = {
          external,
          ...(external ? { href: path } : { to: path }),
          onClick: renderOnClick(),
          className: classnames(`link ${customClass}`, {
            "link-selected": currentPage === path,
            "outgoing-link": external,
            "main-menu-item": section === SECTION.MAIN,
            "toggle-link": item.subLinks,
          }),
          ...(item.subLinks ? { subLinks: item.subLinks } : null),
          children: text,
        };
        return sustainability && path === "/sustainability" ? (
          <div>
            <AOrLink key={i} {...props} />
            <ul className="m-b-0 p-b-0 p-l-30">
              {Object.entries(item.subLinks).map(([k, v]) => {
                return (
                  <li onClick={close} className="sub-link">
                    <Link to={v}>{k}</Link>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <AOrLink key={i} {...props} />
        );
      });
  };

  return (
    <>
      {renderLinks(SECTION.MAIN, "main-link")}
      <div onClick={contactClick} className="link main-menu-item">
        Contact Info
      </div>
      <p
        className={classnames(
          "link main-menu-item toggle-link m-t-20 pointer m-b-10",
          {
            open: extras,
          }
        )}
        onClick={() => toggleExtras(!extras)}
      >
        Extras
      </p>
      <div className={classnames("extras", { show: extras })}>
        {renderLinks(SECTION.EXTRAS, "extras-link")}
      </div>
      <p
        className={classnames(
          "link main-menu-item m-t-10 toggle-link pointer",
          {
            open: partners,
          }
        )}
        onClick={() => togglePartners(!partners)}
      >
        Our Partners
      </p>
      <div className={classnames("partners", { show: partners })}>
        {renderLinks(SECTION.PARTNERS, "partners-link")}
      </div>
    </>
  );
};

export const AOrLink = ({ external, ...props }) => {
  if (external) {
    return (
      <a rel="noopener noreferrer" target="_blank" {...props}>
        {props.children}
      </a>
    );
  }
  return <Link {...props} />;
};
