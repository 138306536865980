import React from "react";
import { withRouter } from "react-router-dom";
import WineBlock from "../components/WineBlock";
import Button from "../components/Button";
import Line from "../images/brush-hr.svg";
import Rosalita from "../images/corner/corner-rosalita.webp";
import classnames from "classnames";
import _ from "underscore";
import KeywordBlock from "../components/KeywordBlock";

class OurWines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wineName: null,
      showWineModal: false,
      searchFilter: [],
      uncommonListSelected: false,
    };
  }

  componentDidMount() {
    document.title = "Auburn Road - Our Wines";
  }
  openWineModal = (wine) => {
    document.getElementById("body").classList.add("scroll-lock");
    this.setState({ wineName: wine });
  };
  hideWineModal = () => {
    this.setState({ wineName: null });
    document.getElementById("body").classList.remove("scroll-lock");
  };
  handleSearch = (e) => {
    const event = e.target.value;
    this.setState({
      searchFilter: event,
    });
  };

  wineSearch = (e) => {
    const selected = e.target.className === "wine-keyword--selected";
    if (selected) {
      const findWine = this.state.searchFilter.indexOf(e.target.outerText);
      const list = this.state.searchFilter;
      list.splice(findWine, 1);
      this.setState({ searchFilter: list });
      e.target.className = "wine-keyword";
    } else {
      this.setState({
        searchFilter: this.state.searchFilter.concat(e.target.outerText),
      });
      e.target.className = "wine-keyword--selected";
    }
  };

  render() {
    let data = this.props.state.filter((x) => {
      if (this.state.searchFilter) {
        return this.state.searchFilter.every(
          (r) => x.fields.keywords && x.fields.keywords.includes(r)
        );
      } else {
        return true;
      }
    });
    const commonKeyWords = [
      "white",
      "red",
      "rose",
      "port",
      "fruit",
      "dry",
      "semi-dry",
      "semi-sweet",
      "sweet",
      "light",
      "medium bodied",
      "medium-to-full bodied",
      "full bodied",
      "crisp",
      "oak",
      "sparkling",
    ];

    let whatsLeft = data
      .map((x) => x.fields.keywords)
      .reduce((accum, item) => {
        const lowerCaseItem = item ? item.map((x) => x.toLowerCase()) : null;
        return [...new Set(accum.concat(lowerCaseItem))].sort();
      }, []);
    const renderWineBlocks = data.map((wine) => {
      const w = wine.fields;
      return (
        <WineBlock
          wineInfo={w}
          key={w.wineName}
          openModal={() => this.openWineModal(wine)}
        />
      );
    });

    const keywordClick = (item) => {
      this.setState({ searchFilter: this.state.searchFilter.concat(item) });
      if (this.state.searchFilter.some((x) => x === item)) {
        const findWine = this.state.searchFilter.indexOf(item);
        const list = this.state.searchFilter;
        list.splice(findWine, 1);
        this.setState({ searchFilter: list });
      }
    };
    return (
      <>
        <div className="container p-b-100 p-t-75">
          <div className="text-sm-center">
            <div
              className={classnames("quote", {
                "hide-quote": !_.isEmpty(this.state.searchFilter),
              })}
            >
              <h1 className="huge-text-no-quote d-inline-block m-t-50 m-md-t-0">
                Our Wines
              </h1>
              <img className="width-100 m-auto m-t-50" src={Line} alt="" />
            </div>
          </div>
          <div className="wine-block-grid">
            {_.isEmpty(data) ? (
              <p>sorry, your search did not return any results</p>
            ) : (
              renderWineBlocks
            )}
          </div>
        </div>
        <img src={Rosalita} className="corner-rosalita" alt="" />
      </>
    );
  }
}

export default withRouter(OurWines);
