import React from "react";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import Hummingbird from "../images/corner/corner-hummingbird.webp";
import Logo from "../images/logo-dark.svg";
import Line from "../images/brush-hr.svg";
import Facebook from "../images/icons/facebook.svg";
import Pinterest from "../images/icons/pinterest.svg";
import Instagram from "../images/icons/instagram.svg";
import YouTube from "../images/icons/youtube.svg";
import moment from "moment";

export default function Footer(props) {
  return (
    <footer className="footer">
      <img className="corner-image" src={Hummingbird} alt="" />
      <div className="container">
        <img
          className="width-200 p-t-20 logo"
          src={Logo}
          alt="auburn road logo"
        />
        <p>
          <a
            href="https://www.google.com/search?q=directions+to+117+Sharptown+Auburn+Road%2C+Pilesgrove%2C+NJ+08098&rlz=1C5CHFA_enUS941US941&oq=directions+to+117+Sharptown+Auburn+Road%2C+Pilesgrove%2C+NJ+08098&aqs=chrome..69i57.3750j0j7&sourceid=chrome&ie=UTF-8"
            target="_blank"
            rel="noreferrer noopener"
            className="i"
          >
            117 Sharptown Auburn Road, Pilesgrove, NJ 08098
          </a>
        </p>
        <div className="flex-1 d-flex social-links py-2">
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.facebook.com/AuburnRoad/"
          >
            <img src={Facebook} alt="facebook icon" />
          </a>
          <a
            className="link"
            rel="noreferrer noopener"
            href="https://www.pinterest.com/arv2458/_saved/"
            target="_blank"
          >
            <img src={Pinterest} alt="pinterest icon" />
          </a>
          <a
            className="link"
            rel="noreferrer noopener"
            href="https://www.instagram.com/auburnroadvineyards/"
            target="_blank"
          >
            <img src={Instagram} alt="instagram icon" />
          </a>
          <a
            className="link"
            rel="noreferrer noopener"
            href="https://www.youtube.com/channel/UCjDWBe2tG2QgdACnturOheA"
            target="_blank"
          >
            <img src={YouTube} alt="youtube icon" />
          </a>
        </div>
      </div>
      <div className="container d-flex flex-column flex-md-row justify-content-baseline align-items-baseline p-b-100 p-md-b-0">
        <div className="flex-1 py-4">
          <h3 className="pb-2">learn more</h3>
          <div className="d-flex flex-column">
            <Link className="link" to="/our-wines">
              Our Wines
            </Link>
            <Link className="link" to="/whats-happening" id="test-click">
              What's Happening
            </Link>
            <Link className="link" to="/faq">
              FAQ
            </Link>
            <Link className="link" to="/the-enoteca">
              The Enoteca Wine Bar
            </Link>
            <Link className="link" to="/wine-stories">
              Wine Stories (blog)
            </Link>
            <Link className="link" to="/spirit-animal">
              Our Spirit Animal Brand
            </Link>
          </div>
        </div>
        <div className="flex-1 py-4">
          <h3 className="pb-2">join us</h3>
          <div className="d-flex flex-column">
            <Link className="link" to="/menu">
              Menu
            </Link>
            <Link className="link" to="/auburn-road-csa">
              Auburn Road Wine CSA
            </Link>
            <Button
              className="link p-0 justify-content-start position-relative outgoing-link"
              href={`https://www.exploretock.com/auburnroadvineyards/search?date=${moment().format(
                "YYYY-MM-DD"
              )}&size=2`}
            >
              Reservations
            </Button>
            <Link className="link" to="/the-enoteca">
              The Enoteca Wine Bar
            </Link>
            <Link className="link" to="/giving-back-to-the-community">
              Auburn Road Gives Back
            </Link>
            <Link className="link" to="/private-events">
              Private Events
            </Link>
          </div>
        </div>
        <div className="flex-1 py-4">
          <h3 className="pb-2">Join our email club</h3>
          <p>
            No cost, no commitment. Just a cool e-newsletter and a lot of great
            benefits!
          </p>
          <form
            action="https://squareup.com/outreach/tUfIZt/subscribe"
            method="POST"
            target="_blank"
          >
            <label>Email</label>
            <input
              className="signup-input"
              type="email"
              name="email_address"
              placeholder="your email address"
            />
            <input type="hidden" name="embed" value="true" />
            <div className="d-flex">
              <Button className="button__secondary mt-3" small type="submit">
                sign up now
              </Button>
              <a
                href="/auburn-road-csa"
                className="button plain-button mt-3 m-l-20 small"
              >
                learn more
              </a>
            </div>
          </form>
        </div>
      </div>
      <div className="text-center p-b-10 w-75 m-auto">
        <span className="credit">
          copyright &copy; {moment().format("YYYY")} Auburn Road, LLC | site
          design & development by{" "}
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="http://www.alvarezbrandingcompany.com"
          >
            Alvarez Branding Co.
          </a>
        </span>
      </div>
    </footer>
  );
}
