import React from "react";
import { CSSTransitionGroup } from "react-transition-group"; // ES6
import "../css/arv.css";
import "../fonts/Walt.ttf";
import Footer from "../components/Footer";
import GoToTop from "../GoToTop";
import Navigation from "../views/Navigation";
import WineStaticPage from "../views/WineStaticPage";
import OurWines from "../views/OurWines";
import WhereToFind from "../views/WhereToFind";
import PrivateEvents from "../views/PrivateEvents";
import TheEnoteca from "../views/TheEnoteca";
import SpiritAnimal from "../views/SpiritAnimal";
import WhatsHappening from "../views/WhatsHappening";
import EmailClub from "../views/WineClub";
import Winemakers from "../views/Winemakers.js";
import DinnerVineyards from "../views/DinnerVineyards.js";
import LiveVineyardStage from "../views/LiveVineyardStage.js";
import ToursTastings from "../views/ToursTastings.js";
import Press from "../views/Press.js";
import Menu from "../views/Menu.js";
import AuburnRoadCSA from "../views/AuburnRoadCSA.js";
import WineStories from "../views/WineStories";
import WineStoryPage from "../views/WineStoryPage";
import Sustainability from "../views/Sustainability";
import GivingBack from "../views/GivingBack";
import FAQ from "../views/FAQ";
import Home from "../views/Home";
import ContactOverlay from "../views/ContactOverlay";
import SecondaryMenu from "../views/SecondaryMenu";
import WinePlaylists from "../views/WinePlaylists";
import NotFoundPage from "../views/NotFoundPage";
import NutriIngredRouter from "../views/NutriIngredRouter";
import Cowtown from "../views/Cowtown";
import GoodKarmaCafe from "../views/GoodKarmaCafe";
import UnderConstruction from "../views/UnderConstruction";
import FullPageLoader from "../components/FullPageLoader";
import Wave1 from "../images/waves/cream-wave-1.svg";
import Wave2 from "../images/waves/cream-wave-2.svg";
import Wave3 from "../images/waves/cream-wave-3.svg";
import Wave4 from "../images/waves/cream-wave-4.svg";
import Wave5 from "../images/waves/cream-wave-5.svg";
import { Switch, Route, withRouter } from "react-router-dom";
const contentful = require("contentful");
const client = contentful.createClient({
  space: "oyiozn1i55xn",
  accessToken: "glrXSewmu2AqnxYCplBZrBIsgVF8nIaTF5WDlQLH9kQ",
});
class CreateRouter extends React.Component {
  constructor(props) {
    super(props);
    this.previousLocation = this.props.location;

    this.state = {
      showModal: false,
      entries: [],
      winePages: null,
      wineStories: null,
      showContact: false,
      loaded: false,
    };
    this.toggleContactOverlay = this.toggleContactOverlay.bind(this);
    client.getEntries({ limit: 200 }).then((x) => {
      this.setState({ entries: x });
    });
    client
      .getEntries({
        content_type: "winePage",
        order: "fields.wineName",
      })
      .then((x) => {
        this.setState({ winePages: x.items });
      });
    client
      .getEntries({
        content_type: "wineStories",
        order: "-sys.createdAt",
      })
      .then((x) => {
        this.setState({ wineStories: x.items });
      });
  }

  componentWillUpdate() {
    const { location } = this.props;
    if (!(location.state && location.state.modal)) {
      this.previousLocation = this.props.location;
    }
  }

  toggleContactOverlay() {
    this.setState(
      {
        showContact: !this.state.showContact,
      },
      () => {
        if (document.querySelector("body").classList.length) {
          document.getElementById("body").classList.remove("scroll-lock");
        } else {
          document.getElementById("body").classList.add("scroll-lock");
        }
      }
    );
  }

  noFooterUrls = ["nutrition-ingredients"];

  render() {
    const { location } = this.props;

    let entriesObj = {};

    if (this.state.entries && this.state.entries.items) {
      this.state.entries.items.forEach((entry) => {
        const entryTitle = entry.sys.contentType.sys.id;
        if (!entriesObj[entryTitle]) {
          entriesObj[entryTitle] = [entry];
        } else {
          entriesObj[entryTitle].push(entry);
        }
      });
    }

    const wineStories = this.state.wineStories;
    const hours =
      this.state.entries.items &&
      this.state.entries.items.filter(
        (x) => x.sys.contentType.sys.id === "hours"
      );
    const wineClub =
      this.state.entries.items &&
      this.state.entries.items.filter(
        (x) => x.sys.contentType.sys.id === "auburnRoadWineClub"
      );
    const cowtownInfo =
      this.state.entries.items &&
      this.state.entries.items.filter(
        (x) => x.sys.contentType.sys.id === "cowtownWagonTour"
      )[0];
    const winePages = this.state.winePages;
    const playlists =
      winePages &&
      winePages.reduce((accum, item) => {
        let newList = [
          ...accum,
          {
            wine: item.fields.wineName,
            playlists: item.fields.playlists,
          },
        ];
        return newList;
      }, []);
    const getWaveImage = () => {
      const waveImages = [Wave1, Wave2, Wave3, Wave4, Wave5];
      const rando = Math.floor(Math.random() * waveImages.length);
      return waveImages[rando];
    };
    if (
      !this.state.entries ||
      !this.state.entries.items ||
      !this.state.entries.items.length > 0
    ) {
      return <FullPageLoader />;
    }

    return (
      <>
        <GoToTop />
        <div className="app">
          <img src={getWaveImage()} className="wave" alt="" />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              exact
              path="/where-to-find-our-wines"
              component={WhereToFind}
            />
            <Route exact path="/whats-happening">
              <WhatsHappening info={entriesObj["whatsHappening"][0]} />
            </Route>
            <Route exact path="/faq">
              <FAQ data={entriesObj["faqPage"]} />
            </Route>
            <Route exact path="/spirit-animal">
              <SpiritAnimal data={entriesObj["spiritAnimal"]} />
            </Route>
            <Route exact path="/wine-stories">
              <WineStories stories={this.state.wineStories} />
            </Route>
            {wineStories ? (
              wineStories.map((x, i, array) => {
                const title =
                  x.fields.uniqueUrl ||
                  x.fields.title.split(" ").join("-").toLowerCase();
                const next = array[i - 1];
                const previous = array[i + 1];
                return (
                  <Route path={"/" + title} key={i}>
                    <WineStoryPage
                      story={x}
                      nextPost={next ? next.fields : null}
                      previousPost={previous ? previous.fields : null}
                    />
                  </Route>
                );
              })
            ) : (
              <FullPageLoader />
            )}
            <Route exact path="/menu">
              <Menu
                items={entriesObj["menuItem"]}
                cellarWines={entriesObj["cellarWine"]}
                hours={hours}
                title="Food and Wine Menu"
              />
            </Route>
            <Route exact path="/menu-local">
              <Menu
                items={entriesObj["menuItem"]}
                cellarWines={entriesObj["cellarWine"]}
                hours={hours}
                title="Local Food and Wine Menu"
              />
            </Route>
            <Route path="/menu/:type">
              <SecondaryMenu
                items={entriesObj["menuItem"]}
                cellarWines={entriesObj["ftcWine"]}
              />
            </Route>
            <Route exact path="/wine-playlists">
              <WinePlaylists playlists={playlists} />
            </Route>
            <Route exact path="/auburn-road-email-club">
              <EmailClub info={wineClub} />
            </Route>
            <Route exact path="/private-events">
              <PrivateEvents info={entriesObj["privateEvents"]} />
            </Route>
            <Route exact path="/sustainability">
              <Sustainability
                sustainabilityInfo={entriesObj["sustainability"]}
              />
            </Route>
            <Route exact path="/giving-back">
              <GivingBack givingBackInfo={entriesObj["givingBackCommunity"]} />
            </Route>
            <Route exact path="/in-the-press">
              <Press items={entriesObj["pressItem"]} />
            </Route>
            <Route exact path="/the-winemakers">
              <Winemakers info={entriesObj["winemakers"][0]} />
            </Route>
            <Route exact path="/dinner-in-the-vineyards">
              <DinnerVineyards info={entriesObj["dinnerInTheVineyards"][0]} />
            </Route>
            
            <Route exact path="/tours-and-tastings">
              <ToursTastings info={entriesObj["toursGuidedTastings"][0]} />
            </Route>
            <Route exact path="/auburn-road-csa">
              <AuburnRoadCSA info={entriesObj["csaPage"]} />
            </Route>
            <Route exact path="/the-enoteca" component={TheEnoteca} />
            {winePages &&
              winePages.map((x) => {
                const hyphenWineName = x.fields.wineName
                  .split(" ")
                  .join("-")
                  .toLowerCase();
                const wine = x.fields.wineName;
                return (
                  <Route exact path={"/" + hyphenWineName} key={wine}>
                    <WineStaticPage wine={x} />
                  </Route>
                );
              })}
            {winePages ? (
              <Route
                path="/nutrition-ingredients/:wine"
                render={() => <NutriIngredRouter wines={winePages} />}
              />
            ) : (
              <FullPageLoader />
            )}
            <Route
              path="/cowtown"
              render={() => <Cowtown info={cowtownInfo} />}
            />
            <Route path="/goodkarmacafephilly" component={GoodKarmaCafe} />
            <Route path="/under-construction" component={UnderConstruction} />
            <Route path="/our-wines">
              {winePages ? <OurWines state={winePages} /> : <FullPageLoader />}
            </Route>
            <Route path="*" component={NotFoundPage} />
          </Switch>
          {this.noFooterUrls.filter((x) => location.pathname.includes(x))
            .length > 0 ? null : (
            <Navigation toggleContactOverlay={this.toggleContactOverlay} />
          )}
          {this.state.showContact ? (
            <CSSTransitionGroup
              transitionName="contact"
              transitionAppear={true}
              transitionAppearTimeout={2000}
              transitionEnter={true}
              transitionLeave={true}
              transitionLeaveTimeout={5000}
            >
              <ContactOverlay
                toggleContactOverlay={this.toggleContactOverlay}
                hours={hours}
              />
            </CSSTransitionGroup>
          ) : null}
        </div>
        {this.noFooterUrls.filter((x) => location.pathname.includes(x)).length >
        0 ? null : (
          <Footer />
        )}
      </>
    );
  }
}
export default withRouter(CreateRouter);
