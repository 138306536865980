import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

const Navigation = ({ show, closeFn, component: Component, content }) => {
  useEffect(() => {
    if (show) {
      const scrollPosition = window.scrollY;
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollPosition}px`;
      document.body.style.width = "100%";
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  }, [show]);
  if (!show) return null;
  return (
    <div className="modal-overlay">
      <div className="modal-close-out" onClick={closeFn} />
      <div className="modal-wrapper">
        <Component content={content} />
      </div>
    </div>
  );
};

export default Navigation;
